var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "form-component",
        {
          attrs: { "copy-data": _vm.versionCopy, validate: true },
          on: {
            "update:copyData": function ($event) {
              _vm.versionCopy = $event
            },
            "update:copy-data": function ($event) {
              _vm.versionCopy = $event
            },
            save: _vm.save,
          },
          model: {
            value: _vm.version,
            callback: function ($$v) {
              _vm.version = $$v
            },
            expression: "version",
          },
        },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { md: "12", sm: "10" } },
                [
                  _c(
                    "b-row",
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c(
                          "h4",
                          {
                            staticStyle: {
                              "text-align": "left",
                              float: "left",
                            },
                          },
                          [
                            _vm._v(
                              "Edit " +
                                _vm._s(_vm.feeSchedule.name) +
                                " Version :\n              " +
                                _vm._s(_vm.version.versionNumber)
                            ),
                          ]
                        ),
                        _c(
                          "h6",
                          {
                            staticStyle: {
                              "text-align": "right",
                              float: "right",
                            },
                          },
                          [_vm._v(" Type: " + _vm._s(_vm.feeSchedule.type))]
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "5" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Type" } },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Type", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.feeScheduleType,
                                            value: _vm.feeSchedule.type,
                                            state:
                                              errors.length > 0 ? false : null,
                                            disabled: "",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "3" } },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Status" } },
                            [
                              _c("validation-provider", {
                                attrs: { name: "Status", rules: "required" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "default",
                                    fn: function (ref) {
                                      var errors = ref.errors
                                      return [
                                        _c("b-form-select", {
                                          attrs: {
                                            options: _vm.feeScheduleStatus,
                                            state:
                                              errors.length > 0 ? false : null,
                                          },
                                          model: {
                                            value: _vm.versionCopy.status,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.versionCopy,
                                                "status",
                                                $$v
                                              )
                                            },
                                            expression: "versionCopy.status",
                                          },
                                        }),
                                        _c(
                                          "small",
                                          { staticClass: "text-danger" },
                                          [_vm._v(_vm._s(errors[0]))]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "Start date" } },
                            [
                              _c("b-form-datepicker", {
                                attrs: { id: "start-date", size: "sm" },
                                model: {
                                  value: _vm.versionCopy.dateStart,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.versionCopy, "dateStart", $$v)
                                  },
                                  expression: "versionCopy.dateStart",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: "End date" } },
                            [
                              _c("b-form-datepicker", {
                                attrs: { id: "end-date", size: "sm" },
                                model: {
                                  value: _vm.versionCopy.dateEnd,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.versionCopy, "dateEnd", $$v)
                                  },
                                  expression: "versionCopy.dateEnd",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("br"),
                ],
                1
              ),
            ],
            1
          ),
          _c("hr"),
          _c("fee-schedule-pricing-table", {
            attrs: {
              "show-pricing-for": false,
              "fee-schedule-version-id":
                +_vm.$route.params.feeScheduleVersionId,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }